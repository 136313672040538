<template>
  <PSection community-league-22s3 theme="black">
    <div class="contents-holder">
      <SectionTitle title="COMMUNITY LEAGUE" title-ko="일반 대회" black />
      <div class="option" v-prx:intro="{ r: [0.5, 2.5], ty: [-50, 0], o: [0, 1] }">
        <TabSelect :items="tabSelectItems" v-model="selectedIndex" />
        <SearchInput v-model="searchText" class="search" :placeholder="'검색어를 입력해주세요.'" @click="searchArenas" />
      </div>
      <template v-if="cardList">
        <NoData
          v-if="!searching && !cardList.length"
          class="game-list-wrap"
          src="/img/pages/icon/History.svg"
          :text=comment(selectedIndex)
          style="background-color:#fff;"
        />
        <div v-else class="game-list-wrap">
          <CommunityGameCard
            v-for="item in items"
            :key="item.id"
            :item="item"
            :lg="item.isSponsored"
          />
          <ScheletonCommunityGameCard v-show="searching" v-for="n in 3" :key="'scel-comucard-' + n" />
        </div>
        <button class="btn more" @click="setListAdd" v-if="hasNext">더보기</button>
      </template>
    </div>
  </PSection>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import NoData from '@/views/components/common/NoData.vue';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import ScheletonCommunityGameCard from '@/views/components/common/gameHome/ScheletonCommunityGameCard.vue';
import CommunityGameCard from '@/views/components/common/gameHome/CommunityGameCard.vue';
import TabSelect from '@/views/components/common/input/TabSelect.vue';
import SearchInput from '@/views/components/common/input/SearchInput.vue';

export default {
  name: 'CommunityLeague',
  components: {
    NoData,
    TabSelect,
    CommunityGameCard,
    ScheletonCommunityGameCard,
    SectionTitle,
    SearchInput,
  },
  props: {},
  data: () => ({
    searchText: '',
    selectedIndex: 0,
    searching: false,
    options: null,
    cardList: null,
    cursor: 0,
    size: 5,
    totalCount: 0,

  }),
  computed: {
    tabSelectItems: () => ['추천', '진행중', '종료'],
    isTextFilled() {
      return this.searchText !== '';
    },
    hasNext() {
      return this.totalCount > this.cardList.length;
    },
    items() {
      if (this.cardList) {
        const items = this.cardList.filter(card => !card.isSponsored);
        if (this.cardList.length > 6 && items.length % 2 !== 0) {
          return this.cardList.slice(0, this.cardList.length - 1);
        }
        return this.cardList;
      }

      return [];
    },
  },
  watch: {
    selectedIndex: 'changedType',
  },
  methods: {
    changedType() {
      this.searchText = '';
      this.resetOption();
      this.getCommunityArena();
    },
    resetOption() {
      this.cursor = 0;
      this.totalCount = 0;
      this.cardList = [];
    },
    searchArenas() {
      if (this.searchText.trim().length === 0) this.searchText = '';
      this.resetOption();
      this.getCommunityArena();
    },
    setListAdd() {
      this.cursor += 1;
      this.getCommunityArena();
    },
    async getCommunityArena() {
      const { selectedIndex } = this;
      const body = _cloneDeep(this.options[selectedIndex]);
      body.q += this.searchText.length ? ` and title like ${encodeURI(this.searchText)}` : '';
      body.cursor = this.cursor;
      body.size = selectedIndex === 1 ? 20 : 6;

      this.searching = true;
      const r = await this.$services.home.getArena(body);
      this.totalCount = r.totalCount;
      this.searching = false;
      this.options[selectedIndex].cursor = r.hasNext ? r.nextCursor : r.currentCursor;
      this.options[selectedIndex].totalCount = r.totalCount;
      this.cardList = [...this.cardList, ...r.items];
    },
    comment(index) {
      if (index === 0) return '추천 일반대회가 없습니다';
      if (index === 1) return '진행중인 일반대회가 없습니다';
      if (index === 2) return '종료된 일반대회가 없습니다';
    },
  },
  mounted() {
  },
  async asyncData({ services }) {
    const params = [
      { q: 'groupCode eq PUBG_AMATEUR_COMMUNITY_3', order: 'recommend asc' },
      { q: 'groupCode eq PUBG_AMATEUR_COMMUNITY_3 AND isEnd eq false', order: 'recommend asc' },
      { q: 'groupCode eq PUBG_AMATEUR_COMMUNITY_3 AND isEnd eq true', order: 'schedule.end desc' },
    ];
    const body = params[0];
    body.cursor = 0;
    body.size = 6;

    const r = await services.home.getArena(body);
    const cursor = r.currentCursor;
    const { totalCount } = r;

    return { cardList: r.items, options: params, cursor, totalCount };
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[community-league-22s3] {.rel;z-index: 1;.bgc(#ebebf0);.p(60, 20, 70);.tc;
  [section-title] {.mb(22);}
  .option {.mb(24);.clear;
    [tab-select] {.fr;}
    .search {.rel;.wh(100%, 40);.mt(20);.block;.fr; .bgc(#fff); .br(50);
      > .search-input-box { .h(100%); }
      input {.-a;.wh(100%);.br(20);.p(0, 50, 0, 16);.-box;.vat;.bgc(#fff);}
      button.search-input_button--search {.br(20);.wh(36);.bgc(white);.abs;.rt(2, 2);z-index: 1;.bgc(#ebebf0);

        &:hover {

        }
        &.active {.bgc(#ebebf0);

        }
      }
    }
  }
  .half-holder {
    [community-game-card] {.mt(12);}
  }
  .game-list-wrap {.crop;.flex;flex-wrap: wrap;justify-content: space-between;}
  .more {.mh-c;.c(rgba(0, 0, 0, 0.4));.tc;.mt(20);}
  @media (@tl-up) {
    .p(60, 58, 132);
    .contents-holder {.rel;
      [section-title] {.mb(112);}
      .option {.abs;.rt(0, 0);.tr;.w(248);
        [tab-select] {.fr;}
        .search {.mt(32);.w(248);}
      }
      .half-holder {
        [community-game-card] {.mt(20);}
      }
      .game-list-wrap {
        // .mr(-20);
      }
    }
  }
  @media (@ds-up) {
    .p(96, 0, 120);
    .contents-holder {.w(@screen-ds-min);.mh-c;.rel;
      .half-holder {.ib-list;
        [community-game-card] {width: calc(50% - 10px);.ml(20);
          &:nth-of-type(odd) {.ml(0);
          }
        }
      }
    }
  }
}
</style>
